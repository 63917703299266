import { debounce } from 'lodash';

import { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';

import { IWidgetControllerConfig } from '../platform.types';
import { createSearchPlatformBiLogger } from '../bi';
import { ISearchResultsWixCode } from '../searchResultsControllerFactory';
import { convertResponseToSuggestions } from '../convertResponseToSuggestions';
import {
  I$W,
  I$WEvent,
  I$WResult,
  ISearchBoxWixCode,
} from './searchAppControllerFactory.types';

export async function searchAppControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const {
    getCategoryList,
    platformAPIs,
    searchLocation,
    searchSDK,
    wixCodeApi,
  } = controllerConfig;
  // TODO: cleanup types when resolved issue with type
  // https://github.com/wix-private/site-search/issues/92
  const $w: I$W = controllerConfig.$w;
  const isMobile = wixCodeApi.window.formFactor === 'Mobile';

  const isSiteViewMode = wixCodeApi.window.viewMode === 'Site';
  const isDemoContent = !isSiteViewMode;
  const reportError = err =>
    controllerConfig.reportError(err, {
      tags: {
        mobile: isMobile,
        ssr: wixCodeApi.window.rendering.env === 'backend',
        demo: isDemoContent,
        fluid: 'unknown',
      },
    });

  const bi = createSearchPlatformBiLogger(
    platformAPIs,
    wixCodeApi,
    reportError,
  );

  return {
    async pageReady() {
      const absoluteUrl = await searchLocation.getSearchResultsAbsoluteUrl();

      const initializationPromises: Array<Promise<any>> = [];

      function withBaseUrl(url: string) {
        return `${absoluteUrl}${url}`;
      }

      const searchBoxes: I$WResult<ISearchBoxWixCode> = $w('@searchBox');
      const searchResults: I$WResult<ISearchResultsWixCode> = $w(
        '@searchResults',
      );

      const hasSearchBoxOnPage = searchBoxes.length > 0;
      const isOnSearchResultsPage = searchResults.length > 0;

      if (!hasSearchBoxOnPage) {
        return;
      }

      /**
       * During migration to ThunderBolt, Platform lost proper support for
       * $('@SearchBox') selector, as discussed here:
       * https://wix.slack.com/archives/CL5TBPB8S/p1596800941479200
       *
       * As a temporary workaround it was suggested to use
       * ```
       * $w("@searchBox").forEach(i => i./*...*\/))
       * ```
       *
       * Pattern:
       * https://wix.slack.com/archives/CL5TBPB8S/p1596958831492700?thread_ts=1596800941.479200&cid=CL5TBPB8S
       *
       * TODO: restore once its fixed in platform: https://jira.wixpress.com/browse/PLAT-884
       *
       * https://jira.wixpress.com/browse/SER-1337
       */
      searchBoxes.forEach(sb => {
        sb.onClear(() => {
          bi.resetRequestCorrelationId();
          // TODO: trigger biEvent here.
          // https://jira.wixpress.com/browse/SER-1345
        });

        sb.onSubmit(e => {
          const searchBox = e.target;
          const searchQuery = searchBox.value;

          bi.searchSubmit({
            isDemoContent,
            searchQuery,
          });

          searchBox.value = '';
          searchBox.suggestions = null;

          if (!isOnSearchResultsPage) {
            // NOTE: navigation with not empty search query works correctly only in `Site` view mode
            // TODO: we could navigate with searchQuery in preview mode when `sv_editorNativeComponents` opened
            void searchLocation.navigateToSearchResults({
              query: isSiteViewMode ? searchQuery : '',
            });
          } else {
            // NOTE: editor version of searchResults component, doesn't return valid controller instance
            // so `changeQuery` call does not affect search results component
            // should be fixed when `sv_editorNativeComponents` opened
            // https://github.com/wix-private/site-search/issues/130
            searchResults.changeQuery(searchQuery);
          }
        });
      });

      /**
       * Clear all suggestions if mobile.
       * If switch to desktop editor script will set demo suggestion again
       */
      if (isMobile) {
        searchBoxes.forEach(() => {
          searchBoxes.suggestions = null;
        });
      }

      if (!isDemoContent && !isMobile) {
        const loadSuggestions = (minimumSearchTermLength: number) => async (
          e: I$WEvent<ISearchBoxWixCode>,
        ) => {
          const searchBox = e.target;
          const searchQuery = searchBox.value;

          if (!searchBox.suggestionsEnabled) {
            return;
          }

          if (!searchQuery) {
            bi.resetRequestCorrelationId();
          }

          if (searchQuery.length < minimumSearchTermLength) {
            searchBox.suggestions = null;
            return;
          }

          const biSearchBoxSuggestionsRequestFinished = bi.searchBoxSuggestionsRequestStarted(
            { searchQuery },
          );

          try {
            const federatedResponse = await searchSDK.getFederatedSuggestions({
              query: searchQuery,
              limit: 4,
            });
            const categoryList = await getCategoryList();

            const searchAllRelativeUrl = searchLocation.encodePath({
              query: searchQuery,
            });

            const searchAllUrl = withBaseUrl(`/${searchAllRelativeUrl}`);

            const federatedSuggestions = convertResponseToSuggestions({
              federatedResponse,
              categoryList,
              searchQuery,
              footerUrl: searchAllUrl,
              searchLocation,
              absoluteUrl,
            });

            searchBox.suggestions = federatedSuggestions;

            biSearchBoxSuggestionsRequestFinished({
              success: true,
              suggestions: federatedSuggestions.items,
            });
          } catch (error) {
            biSearchBoxSuggestionsRequestFinished({
              success: false,
              error: error.toString(),
              suggestions: [],
            });

            reportError(error);

            throw error;
          }
        };

        initializationPromises.push(
          (async () => {
            const isShorterSearchTermEnabled = (
              await controllerConfig.featureToggles
            ).shorterSearchTermEnabled;
            searchBoxes.forEach(sb => {
              sb.onChange(
                debounce(
                  loadSuggestions(isShorterSearchTermEnabled ? 1 : 3),
                  isShorterSearchTermEnabled ? 100 : 300,
                ),
              );
            });
          })(),
        );

        searchBoxes.forEach(sb => {
          sb.onSubmit(e => {
            const searchBox = e.target;
            searchBox.suggestions = null;
          });
        });

        searchBoxes.forEach(sb => {
          sb.onSuggestionSelect(e => {
            const searchBox = e.target;
            const {
              url,
              query,
              globalIndex,
              groupId,
              type,
            } = e.syntheticEvent.data;

            if (type === 'item') {
              bi.searchBoxSuggestionClick({
                url,
                searchQuery: query,
                index: globalIndex,
                documentType: groupId,
                suggestions: searchBox.suggestions?.items || [],
              });
            }
            // Compatibility with Bolt component (clicking on a suggestions group uses onSuggestionSelect()
            // in Bolt and onSuggestionGroupSelect() in Thunderbolt).
            else {
              bi.searchBoxSuggestionShowAllClick({
                searchQuery: query,
                documentType: groupId,
              });
            }

            searchBox.suggestions = null;

            const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');
            wixCodeApi.location.to?.(relativeUrl);
          });
        });

        searchBoxes.forEach(sb => {
          sb.onSuggestionsFooterClick(e => {
            const query = e.target.value;
            bi.searchBoxSuggestionSearchAllClick({
              searchQuery: query,
            });
            searchBoxes.forEach(sbb => {
              sbb.suggestions = null;
            });
            searchLocation
              .navigateToSearchResults({ query })
              .catch(err => reportError(err));
          });
        });

        searchBoxes.forEach(sb => {
          sb.onSuggestionGroupSelect(e => {
            /* SearchBox::Bolt does not use `onSuggestionGroupSelect`:
             * https://github.com/wix-private/wix-ui-santa/search?q=onSuggestionGroupSelect
             * https://github.com/wix-private/site-search/search?q=handleSuggestionGroupSelect
             *
             * However old implementation of API leaked to the users at least once.
             *
             * In Bolt implementation of SearchBox, fields (url, query, groupId) were defined
             * directly on `syntheticEvent` (without `data` layer).
             *
             * In SearchBox::TB we moved to unified solution and introduced `syntheticEvent.data`.
             *
             * This guard should help to avoid unexpected failures in the user code.
             */
            if (!e.syntheticEvent?.data) {
              return;
            }

            const { url, query, groupId } = e.syntheticEvent.data;
            searchBoxes.forEach(sbb => {
              sbb.suggestions = null;
            });

            bi.searchBoxSuggestionShowAllClick({
              searchQuery: query,
              documentType: groupId,
            });

            const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');
            wixCodeApi.location.to?.(relativeUrl);
          });
        });
      }
      await Promise.all(initializationPromises);
    },
  };
}
