import {
  ClientSearchSDK,
  IErrorResponse,
  ISearchRequest,
  ISearchResponse,
  ISearchResponseTotals,
  SearchDocumentType,
} from '@wix/client-search-sdk';

import { CategorySettings } from '@wix/search-results-settings-definitions';

import { ISearchLocation } from '../location';
import { SearchRequestBi } from '../bi';
import { ISearchSample } from '../searchResultsControllerStore';
import { getSearchResponses } from './getSearchResponses';
import { sortCategories } from './sortCategories';
import { getDocumentIds } from './getDocumentIds';

type SearchParams = {
  bi: SearchRequestBi;
  isMobile: boolean;
  previousQuery: string | undefined;
  previousTotals: ISearchResponseTotals | undefined;
  searchLocation: ISearchLocation;
  searchRequest: ISearchRequest;
  searchResultsAbsoluteUrl: string;
  searchSDK: ClientSearchSDK;
  visibleCategories: Array<[string, CategorySettings]>;
};

export const search = async ({
  bi,
  isMobile,
  previousQuery,
  previousTotals,
  searchLocation,
  searchRequest,
  searchResultsAbsoluteUrl,
  searchSDK,
  visibleCategories,
}: SearchParams): Promise<
  | {
      searchResponse: ISearchResponse;
      searchResponseTotals: ISearchResponseTotals;
      searchSamples: ISearchSample[];
    }
  | IErrorResponse
> => {
  try {
    bi.started();

    const shouldShowSamples =
      searchRequest.documentType === SearchDocumentType.All;

    const sortedCategories = sortCategories(visibleCategories);

    const [
      searchResponse,
      searchResponseTotals,
      searchSampleResponse,
    ] = await getSearchResponses(
      searchRequest,
      shouldShowSamples,
      searchSDK,
      isMobile,
      previousQuery,
      previousTotals || {},
    );

    if ('isError' in searchResponse) {
      return searchResponse;
    }

    if ('isError' in searchResponseTotals) {
      return searchResponseTotals;
    }

    if ('isError' in searchSampleResponse) {
      return searchSampleResponse;
    }

    if (shouldShowSamples) {
      searchResponse.totalResults =
        searchResponseTotals[SearchDocumentType.All] || 0;
    }

    const searchSamples: ISearchSample[] = searchSampleResponse.results
      .map(s => ({
        ...s,
        url: searchLocation.buildSearchResultsUrl(searchResultsAbsoluteUrl, {
          query: searchRequest.query,
          documentType: s.documentType,
        }),
      }))
      .sort(
        (s1, s2) =>
          sortedCategories.indexOf(s1.documentType) -
          sortedCategories.indexOf(s2.documentType),
      );

    bi.finished(
      searchResponse,
      searchResponseTotals,
      getDocumentIds({
        searchResponse,
        searchSamples,
        shouldShowSamples,
      }),
    );
    return { searchResponse, searchResponseTotals, searchSamples };
  } catch (error) {
    bi.failed(error);
    throw error;
  }
};
